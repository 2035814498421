import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { DemographicDetailsWizard } from '../demographic-details-wizard';
import { RegionType } from '../../../../../core/location/region-type.enum';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { formatAsCurrency } from '../../../../../core/util/string-utils';
import { GeometryUtil } from '../../../../../core/util/geometry-util';
import { LocationService } from '../../../../../core/location/location.service';
import { ToastService } from '../../../../../core/toast/toast.service';
import { AppState } from 'src/app/app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailState, selectDetailState } from '../../../../detail-state';
import { FeatureCollection } from '../../../../../core/models';
import { DemographicsService } from '../../../../../core/depot/demographics.service';
import { DemographicDataView } from '../../../../../core/depot/demographic-data-view';
import { round } from 'src/app/core/util/math-utils';

@Component({
  selector: 'mtn-demographic-details-page',
  templateUrl: './demographic-details-page.component.html',
  styleUrls: ['./demographic-details-page.component.scss']
})
export class DemographicDetailsPageComponent extends WizardPageComponent<DemographicDetailsWizard> {

  key = 'demographic-details';

  private readonly VALIDATORS_DRIVE_TIME = [Validators.min(1), Validators.max(20)];
  private readonly VALIDATORS_RING = [Validators.min(1), Validators.max(10)];

  public readonly EDUCATION_DESCRIPTION = 'These values represent the population\'s highest-attained level of education.';

  demographics: DemographicDataView;
  formatAsCurrency = formatAsCurrency;
  isLargeArea = false;
  isLoading = false;
  pop2yrChange: string;
  pop5yrProjection: string;
  RegionType = RegionType;
  round = round;

  constructor(private demographicsService: DemographicsService,
              private locationService: LocationService,
              private ngrxStore: NgrxStore<AppState>,
              private toaster: ToastService) {
    super();
    this.title = 'Demographic Details';
    this.closeButtonText = 'Close';
    this.form = new UntypedFormGroup({
      region: new UntypedFormControl(RegionType.DRIVE_TIME),
      driveTimeMinutes: new UntypedFormControl(7, [...this.VALIDATORS_DRIVE_TIME, Validators.required]),
      ringMiles: new UntypedFormControl(3, this.VALIDATORS_RING)
    });
  }

  onLoad(): Observable<any> {
    this.setDemographics(this.wizard.model.demographics);
    this.subscribeToFormChanges();
    return super.onLoad();
  }

  private getSelectedRegionShape(regionType: RegionType): Observable<FeatureCollection> {
    this.isLargeArea = false;
    switch (regionType) {
      case RegionType.DRIVE_TIME:
        const minutes = this.form.get('driveTimeMinutes').value;

        if (minutes === 7) {
          return selectDetailState(this.ngrxStore)
            .pipe(take(1),
              map((state: DetailState) => state.defaultDriveTime));
        } else {
          if (minutes > 10) {
            this.isLargeArea = true;
          }
          return this.locationService.findOnesIsochrone(this.wizard.model.store.space.location.uuid, minutes);
        }
      case RegionType.MARKET_AREA:
        this.isLargeArea = true;
        return of(this.wizard.model.marketArea);
      case RegionType.RING:
        const radius = this.form.get('ringMiles').value;
        this.isLargeArea = radius > 5;
        const circle = new google.maps.Circle({
          center: this.wizard.model.store.space.location.getPointAsLatLng(),
          radius: radius
        });
        return of(GeometryUtil.googleCircleToGeoJsonFeatureCollection(circle));
    }
  }

  private loadDemographics(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.demographics = null;

      const formValue = this.form.getRawValue();

      this.getSelectedRegionShape(formValue.region)
        .pipe(
          catchError((err) => {
            console.error(err);
            this.toaster.error("Uh-oh! We weren't able to load the Demographic Profile for this store. If the problem persists, please contact an administrator for assistance.");
            this.isLoading = false;
            return of(null);
          }),
          switchMap((regionGeoJson: FeatureCollection) => {
            return this.demographicsService.findDemographicsForFeatureCollection(regionGeoJson)
              .pipe(tap((result: DemographicDataView) => {
                setTimeout(() => {
                  this.setDemographics(result);
                  this.isLoading = false;
                }, 300);
              }));
          })
        ).subscribe();
    }
  }

  private setDemographics(demographics: DemographicDataView): void {
    this.demographics = demographics;

    const change = this.demographics.populationHistoricalGrowth2Year;
    const changeSign = change > 0 ? '+' : '';
    this.pop2yrChange = `${changeSign}${(change * 100).toFixed(2)}%`;

    const projection = this.demographics.populationProjectedGrowth5Year;
    const projectionSign = projection > 0 ? '+' : '';
    this.pop5yrProjection = `${projectionSign}${(projection * 100).toFixed(2)}%`;
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.get('region').valueChanges
        .subscribe((value: RegionType) => {
          //Update driveTimeMinutes validators
          const driveTimeMinutesValidators = [...this.VALIDATORS_DRIVE_TIME];
          if (value === RegionType.DRIVE_TIME) {
            driveTimeMinutesValidators.push(Validators.required)
          }
          this.form.get('driveTimeMinutes').setValidators(driveTimeMinutesValidators);

          //Update ringMiles validators
          const ringMilesValidators = [...this.VALIDATORS_RING];
          if (value === RegionType.RING) {
            ringMilesValidators.push(Validators.required);
          }
          this.form.get('ringMiles').setValidators(ringMilesValidators);

          this.form.updateValueAndValidity();
        })
    );

    this.addSubscription(
      this.form.valueChanges
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (this.form.dirty && this.form.valid) {
            this.loadDemographics();
          }
        })
    );
  }

}
