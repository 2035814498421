import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { AboutWizard } from '../about-wizard';
import { Observable } from 'rxjs';

// @ts-ignore
import BuildProperties from '../../../../../../build-properties.json';

@Component({
  selector: 'mtn-about-wizard-page',
  templateUrl: './about-wizard-page.component.html',
  styleUrls: ['./about-wizard-page.component.scss']
})
export class AboutWizardPageComponent extends WizardPageComponent<AboutWizard> {

  buildNumber: number;
  commitHash: string;
  key = 'about';

  constructor() {
    super();
    this.title = 'About MTN Insights';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.buildNumber = Number(BuildProperties.buildNumber);
    this.commitHash = BuildProperties.commitHash;

    return super.onLoad();
  }

}
