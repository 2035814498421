import {HttpClient} from '@angular/common/http';
import {CircleRequest} from '../shared/shape/circle-request';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {InsightsRestService} from './insights-rest-service';
import {FeatureCollection} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends InsightsRestService  {

  private readonly path = '/report';
  constructor(protected http: HttpClient) {
    super();
  }

  createOne(request: CircleRequest): Observable<FeatureCollection> {
    const url = this.buildUrl(`${this.path}/circle`);
    return this.http.post(url, request)
      .pipe(map((result: any) => new FeatureCollection(result)));
  }
}
