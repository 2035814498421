import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { CompetitiveAnalysisDetailsWizard } from '../competitive-analysis-details-wizard';
import { StoreComparison } from '../../../../../../core/store/ranking/store-comparison';
import { StoreRanking } from '../../../../../../core/store/ranking/store-ranking';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StoreComparisonService } from '../../../../../../core/store/ranking/store-comparison.service';
import { catchError, debounceTime, finalize, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { convertToNth } from 'src/app/core/util/string-utils';
import { RegionType } from 'src/app/core/location/region-type.enum';
import { ChartOptions, ChartTooltipModel } from 'chart.js';
import * as chroma from 'chroma-js';
import {
  buildRankingDescriptor,
  buildStoreRankingGroupDescriptor,
  buildStoreRankingTypeDescriptor
} from '../../comparison-utils';
import { ComparisonType } from '../../../../../../core/store/ranking/comparison-type.enum';
import { Definition } from '../../../../../../core/definition/definition';
import { FitType } from '../../../../../../core/store/fit/fit-type.enum';
import { DefinitionService } from '../../../../../../core/definition/definition.service';
import { AxisMode } from '../../../../../../core/chart/number-line-chart/AxisMode';
import { ChartAxisBuilder } from '../../../../../../core/chart/number-line-chart/chart-axis-builder';
import { NumberLineDataset } from '../../../../../../core/chart/number-line-chart/number-line-dataset';
import { ToastService } from '../../../../../../core/toast/toast.service';
import { FeatureCollection } from '../../../../../../core/models';

@Component({
  selector: 'mtn-competitive-analysis-details-page',
  templateUrl: './competitive-analysis-details-page.component.html',
  styleUrls: ['./competitive-analysis-details-page.component.scss']
})
export class CompetitiveAnalysisDetailsPageComponent extends WizardPageComponent<CompetitiveAnalysisDetailsWizard> {

  private readonly GRADIENT = chroma.scale(['red', 'orange', 'limegreen']);
  private readonly VALIDATORS_DRIVE_TIME = [Validators.min(1), Validators.max(20)];
  private readonly VALIDATORS_RING = [Validators.min(1), Validators.max(10)];

  readonly excludedStoresTooltip = `Store(s) Excluded from Ranking

One or more stores were excluded from the ranking due to missing data. This typically occurs when the store is in a rural area, or represents an insignificant impact to the market. See table below for details.
  `;

  key = 'competitive-analysis-details';

  comparison: StoreComparison;
  ranking: StoreRanking;

  datasets: NumberLineDataset[] = [];
  numberChartAxisBuilder: ChartAxisBuilder;

  chartColors: any[];
  chartDataSets: any[];
  chartLabels: string[];
  chartOptions: ChartOptions;
  isLoading = false;
  isRelativeMode = true;
  labelComparisonType: string;
  labelPercentile: string;
  labelRegion: string;
  marketArea: FeatureCollection;
  rankingDescriptor: string;
  rankingGroupDescriptor: string;
  rankingTypeDescriptor: string;
  RegionType = RegionType;

  constructor(private definitionService: DefinitionService,
              private storeComparisonService: StoreComparisonService,
              private toaster: ToastService) {
    super();
    this.title = 'Sales Performance Detail';
    this.closeButtonText = 'Close';
  }

  private initNumChart() {
    this.datasets = [];
    const ds = new NumberLineDataset(this.comparison.store, this.comparison.rankings)

    const mode = this.isRelativeMode ? AxisMode.RELATIVE : AxisMode.ABSOLUTE;
    const axisBuilder = new ChartAxisBuilder(mode, this.comparison.keyIndicator);
    axisBuilder.extend(ds.minValue);
    axisBuilder.extend(ds.maxValue);
    this.numberChartAxisBuilder = axisBuilder;

    this.datasets.push(ds)
  }

  onLoad(): Observable<any> {
    this.comparison = this.wizard.model.comparison;
    this.ranking = this.comparison.getRankingForStore(this.comparison.store.uuid);

    this.marketArea = this.wizard.model.marketArea;
    this.title = `Competitive Analysis (${this.comparison.store.name}${this.comparison.store.number ? ' - ' + this.comparison.store.number : ''})`;

    this.initForm();

    return super.onLoad();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      comparisonType: new UntypedFormControl(this.comparison.comparisonType, [Validators.required]),
      driveTimeMinutes: new UntypedFormControl(this.comparison.driveTimeMinutes || 7, this.VALIDATORS_DRIVE_TIME),
      keyIndicator: new UntypedFormControl(this.comparison.keyIndicator, [Validators.required]),
      region: new UntypedFormControl(this.comparison.region, [Validators.required]),
      ringMiles: new UntypedFormControl(this.comparison.ringMiles || 3, this.VALIDATORS_RING)
    });

    this.addSubscription(
      this.form.valueChanges
        .pipe(debounceTime(300))
        .subscribe((formValue: any) => {
          const newComparison = new StoreComparison(this.comparison);
          Object.assign(newComparison, formValue);

          this.loadComparison(newComparison);
        })
    );
  }

  private initChart(): void {
    this.chartDataSets = [{
      data: [this.ranking.percentile, 100 - this.ranking.percentile],
      label: 'Store Ranking'
    }];
    this.chartLabels = [`${convertToNth(this.ranking.percentile)} Percentile`, null];

    const borderColor = this.GRADIENT(this.ranking.percentile / 100).css();
    const backgroundColor = borderColor.replace(')', ',0.6)');
    const hoverBackgroundColor = borderColor.replace(')', ',0.5)');
    this.chartColors = [
      {
        backgroundColor: [backgroundColor, 'rgba(0,0,0,0.1)'],
        borderColor: [borderColor, 'rgba(0,0,0,0)'],
        hoverBackgroundColor: [hoverBackgroundColor, 'rgba(0,0,0,0.1)'],
        borderWidth: 3
      }
    ];
    this.chartOptions = {
      cutoutPercentage: 75,
      tooltips: {
        bodyAlign: 'center',
        displayColors: false,
        custom: (tooltipModel: ChartTooltipModel) => {
          let index = 1;
          if (tooltipModel.dataPoints) {
            index = tooltipModel.dataPoints[0].index;
            tooltipModel.body[0].lines = [this.chartLabels[0]]
          }

          tooltipModel.opacity = index ? 0 : 1;
          tooltipModel.width = 100;
        }
      }
    };
  }

  private loadComparison(request: StoreComparison): void {
    this.isLoading = true;
    this.storeComparisonService.findOne(request)
      .pipe(
        take(1),
        catchError(() => {
          this.toaster.error('Uh-oh! Something went wrong loading the comparison. If the problem continues, contact MTN for assistance.');
          this.isLoading = false;
          throw 'failed to load comparison';
        }),
        finalize(() => this.isLoading = false)
      ).subscribe((result: StoreComparison) => {
      const resultCopy = new StoreComparison(result);
      resultCopy.store = this.comparison.store;
      this.comparison = resultCopy;
      this.ranking = resultCopy.getRankingForStore(resultCopy.store.uuid);
      this.rankingDescriptor = buildRankingDescriptor(this.ranking);
      this.rankingGroupDescriptor = buildStoreRankingGroupDescriptor(this.comparison);
      this.rankingTypeDescriptor = buildStoreRankingTypeDescriptor(this.comparison);
      this.labelPercentile = convertToNth(this.ranking.percentile);

      this.setComparisonTypeLabel();
      this.setRegionLabel();
      this.initChart();
      this.initNumChart();
    });
  }

  private setComparisonTypeLabel(): void {
    switch (this.comparison.comparisonType) {
      case ComparisonType.ALL:
        this.labelComparisonType = 'All Grocery Stores';
        break;
      case ComparisonType.BANNER:
        if (this.comparison.store.banner) {
          this.labelComparisonType = `All ${this.comparison.store.banner.name} Stores`;
        } else {
          this.labelComparisonType = 'All Banner Stores';
        }
        break;
      case ComparisonType.FIT_CATEGORY:
        this.definitionService.findAllFits()
          .subscribe((definitions: Definition<FitType>[]) => {
            const definition = _.find(definitions, (definition: Definition<FitType>) => definition.systemName === this.comparison.store.fit);
            if (definition) {
              this.labelComparisonType = `All ${definition.displayName} FIT Stores`;
            } else {
              this.labelComparisonType = 'All FIT Stores';
            }
          });
        break;
    }
  }

  private setRegionLabel(): void {
    switch (this.comparison.region) {
      case RegionType.DRIVE_TIME:
        this.labelRegion = `${this.comparison.driveTimeMinutes}-Minute Drive Time`;
        break;
      case RegionType.MARKET_AREA:
        // @ts-ignore
        const marketName = this.actualComparison?.geoJson?.getFeature()?.properties['NAME'];
        if (marketName) {
          this.labelRegion = `Market: ${marketName}`;
        } else {
          this.labelRegion = 'Market Area';
        }
        break;
      case RegionType.RING:
        this.labelRegion = `${this.comparison.ringMiles}-Mile Ring`;
        break;
      case RegionType.TRADE_AREA:
        this.labelRegion = 'MTN Trade Area';
        break;
    }
  }

}
