import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegionType } from '../../../core/location/region-type.enum';
import { SpinnerSize } from '../../../core/util/spinner/spinner-size.enum';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailState, selectDetailState } from '../../detail-state';
import { catchError, debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FeatureCollection, Store } from '../../../core/models';
import { Observable, of } from 'rxjs';
import { LocationService } from '../../../core/location/location.service';
import { ToastService } from '../../../core/toast/toast.service';
import { DemographicFieldType } from '../../../core/depot/demographic-field-type.enum';
import * as _ from 'lodash';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { SAMPLE_DEMOGRAPHIC_DATA } from './sample-demographic-data';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { DemographicDetailsWizard } from './demographic-details-wizard/demographic-details-wizard';
import { formatAsCurrency } from '../../../core/util/string-utils';
import { PartnerType } from '../../../core/footer/partner-attribution/partner-type.enum';
import { round } from '../../../core/util/math-utils';
import { DetailSetDemographicsAction } from '../../detail-actions';
import { StorageService } from '../../../core/util/storage.service';
import { DemographicDataView } from '../../../core/depot/demographic-data-view';
import { DemographicsService } from '../../../core/depot/demographics.service';
import {CircleRequest} from '../../../core/shared/shape/circle-request';
import {ReportService} from '../../../core/service/report.service';

@Component({
  selector: 'mtn-store-demographic-profile-panel',
  templateUrl: './store-demographic-profile-panel.component.html',
  styleUrls: ['./store-demographic-profile-panel.component.scss']
})
export class StoreDemographicProfilePanelComponent extends AuthorizationAwareComponent implements OnInit {

  private readonly VALIDATORS_DRIVE_TIME = [Validators.min(1), Validators.max(20)];
  private readonly VALIDATORS_RING = [Validators.min(1), Validators.max(10)];

  public readonly PANEL_DESCRIPTION = 'This is a profile of the population in this store\'s local area. Consider this profile to be the first step towards understanding this store\'s target customer base, and how the local demographics may be affecting this store\'s performance.';
  private readonly WHITE_LABEL = 'White';
  private readonly BLACK_LABEL = 'Black';
  private readonly HISPANIC_LABEL = 'Hispanic';
  private readonly ASIAN_LABEL = 'Asian';
  private readonly OTHER_LABEL = 'Other';

  demographics: DemographicDataView;
  form = new UntypedFormGroup({
    region: new UntypedFormControl(RegionType.DRIVE_TIME),
    driveTimeMinutes: new UntypedFormControl(7, this.VALIDATORS_DRIVE_TIME),
    ringMiles: new UntypedFormControl(3, this.VALIDATORS_RING)
  });
  ethnicityDataSet: any[];
  ethnicityLabels: string[] = [];
  incomeAverage: string;
  incomeMedian: string;
  isLargeArea = false;
  isLoading = false;
  marketArea: FeatureCollection;
  PartnerType = PartnerType;
  pop2yrChangeText: string;
  pop5yrProjectionText: string;
  round = round;

  RegionType = RegionType;
  SpinnerSize = SpinnerSize;

  private store: Store;
  private tradeArea: FeatureCollection;

  constructor(private demographicsService: DemographicsService,
              private locationService: LocationService,
              private reportService: ReportService,
              protected ngrxStore: NgrxStore<AppState>,
              private storageService: StorageService,
              private toaster: ToastService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.init();
  }

  openDetailsWizard(): void {
    const wizard = new DemographicDetailsWizard();
    wizard.model = {
      demographics: this.demographics,
      marketArea: this.marketArea,
      store: this.store
    };

    this.wizardRunner.run(wizard);
  }

  private aggregateDemographics(): void {
    this.buildEthnicityDataSet();

    const population2YearChangePercent = this.demographics.populationHistoricalGrowth2Year;
    const changeSign = population2YearChangePercent > 0 ? '+' : '';
    this.pop2yrChangeText = `${changeSign}${round(population2YearChangePercent * 100, 2)}%`;

    const projection = this.demographics.populationProjectedGrowth5Year
    const projectionSign = projection > 0 ? '+' : '';
    this.pop5yrProjectionText = `${projectionSign}${round(projection * 100, 2)}%`;

    this.incomeAverage = formatAsCurrency(this.demographics.householdsAverageIncome, false);
    this.incomeMedian = formatAsCurrency(this.demographics.householdsMedianIncome, false);

    this.isLoading = false;
  }

  private buildEthnicityDataSet(): void {
    const valueWrappers: any[] = [];

    valueWrappers.push({
      type: DemographicFieldType.ETHNICITY_WHITE,
      value: round(this.demographics.ethnicityWhite * 100, 1)
    });

    valueWrappers.push({
      type: DemographicFieldType.ETHNICITY_BLACK,
      value: round(this.demographics.ethnicityBlack * 100, 1)
    });

    valueWrappers.push({
      type: DemographicFieldType.ETHNICITY_HISPANIC,
      value: round(this.demographics.ethnicityHispanic * 100, 1)
    });

    valueWrappers.push({
      type: DemographicFieldType.ETHNICITY_ASIAN,
      value: round(this.demographics.ethnicityAsian * 100, 1)
    });

    valueWrappers.push({
      type: DemographicFieldType.ETHNICITY_OTHER,
      value: round(this.demographics.ethnicityOther * 100, 1)
    });

    const data: number[] = [];
    const labels: string[] = [];

    const orderedValueWrappers = _.orderBy(valueWrappers, 'value', 'desc');
    orderedValueWrappers.forEach((wrapper: any) => {
      data.push(wrapper.value);

      switch (wrapper.type) {
        case DemographicFieldType.ETHNICITY_WHITE:
          labels.push(this.WHITE_LABEL);
          break;
        case DemographicFieldType.ETHNICITY_BLACK:
          labels.push(this.BLACK_LABEL);
          break;
        case DemographicFieldType.ETHNICITY_HISPANIC:
          labels.push(this.HISPANIC_LABEL);
          break;
        case DemographicFieldType.ETHNICITY_ASIAN:
          labels.push(this.ASIAN_LABEL);
          break;
        case DemographicFieldType.ETHNICITY_OTHER:
          labels.push(this.OTHER_LABEL);
          break;
      }
    });

    this.ethnicityDataSet = [{
      data: data,
      label: 'Ethnicity Data Set'
    }];

    this.ethnicityLabels = labels;
  }

  private getSelectedRegionShape(regionType: RegionType): Observable<FeatureCollection> {
    this.isLargeArea = false;
    switch (regionType) {
      case RegionType.DRIVE_TIME:
        const minutes = this.form.get('driveTimeMinutes').value;

        if (minutes === 7) {
          return selectDetailState(this.ngrxStore)
            .pipe(
              filter((state: DetailState) => !!state.defaultDriveTime),
              take(1),
              map((state: DetailState) => state.defaultDriveTime));
        } else {
          if (minutes > 10) {
            this.isLargeArea = true;
          }
          return this.locationService.findOnesIsochrone(this.store.space.location.uuid, minutes);
        }
      case RegionType.MARKET_AREA:
        this.isLargeArea = true;
        return of(this.marketArea);
      case RegionType.RING:
        const radius = this.form.get('ringMiles').value;
        this.isLargeArea = radius > 5;
        /*const circle = new google.maps.Circle({
          center: this.store.space.location.getPointAsLatLng(),
          radius: radius
        });*/
        const circleRequest: CircleRequest = new CircleRequest();
        circleRequest.point = {
          type: 'Point',
          coordinates: [this.store.space.location.getPointLongitude(), this.store.space.location.getPointLatitude()]
        };
        circleRequest.distanceMiles = radius;

        return this.reportService.createOne(circleRequest);
      case RegionType.TRADE_AREA:
        return of(this.tradeArea);
    }
  }

  private init(): void {
    this.storageService.get(StorageService.DEMOGRAPHIC_PROFILE_REGION)
      .subscribe((value: RegionType) => {
        if (value && value !== this.form.get('region').value) {
          this.form.get('region').setValue(value, {emitEvent: false});
        }
      });

    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(debounceTime(300))
        .pipe(filter((state: DetailState) => !!state.store && (!this.hasStandardLicense || !!state.marketArea)))
        .pipe(
          take(1),
          switchMap((state: DetailState) => {
            this.store = state.store;
            this.marketArea = state.marketArea;
            this.tradeArea = state.tradeArea;

            return this.loadDemographics();
          })
        )
        .subscribe()
    );

    this.addSubscription(
      this.form.get('region').valueChanges
        .subscribe((value: RegionType) => {
          this.storageService.set(StorageService.DEMOGRAPHIC_PROFILE_REGION, value).subscribe();

          //Update driveTimeMinutes validators
          const driveTimeMinutesValidators = [...this.VALIDATORS_DRIVE_TIME];
          if (value === RegionType.DRIVE_TIME) {
            driveTimeMinutesValidators.push(Validators.required)
          }
          this.form.get('driveTimeMinutes').setValidators(driveTimeMinutesValidators);

          //Update ringMiles validators
          const ringMilesValidators = [...this.VALIDATORS_RING];
          if (value === RegionType.RING) {
            ringMilesValidators.push(Validators.required);
          }
          this.form.get('ringMiles').setValidators(ringMilesValidators);

          this.form.updateValueAndValidity();
        })
    );

    this.addSubscription(
      this.form.valueChanges
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (this.form.valid) {
            this.loadDemographics().subscribe();
          }
        })
    );
  }

  private loadDemographics(): Observable<DemographicDataView> {
    this.isLoading = true;
    const formValue = this.form.getRawValue();

    if (this.hasStandardLicense) {
      return this.getSelectedRegionShape(formValue.region)
        .pipe(
          catchError((err) => {
            console.error(err);
            this.toaster.error("Uh-oh! We weren't able to load the Demographic Profile for this store. If the problem persists, please contact an administrator for assistance.");
            this.isLoading = false;
            return of(null);
          }),
          switchMap((regionGeoJson: FeatureCollection) => {
            return this.demographicsService.findDemographicsForFeatureCollection(regionGeoJson)
              .pipe(tap((result: DemographicDataView) => {
                this.demographics = result;
                this.ngrxStore.dispatch(DetailSetDemographicsAction({demographics: result}));
                this.aggregateDemographics();
              }));
          })
        );
    } else {
      this.isLoading = false;
      this.demographics = SAMPLE_DEMOGRAPHIC_DATA;
      this.ngrxStore.dispatch(DetailSetDemographicsAction({
        demographics: SAMPLE_DEMOGRAPHIC_DATA,
        isDemographicsSampleData: true
      }));
      this.aggregateDemographics();
      return of(null);
    }
  }

}
