import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as p5 from 'p5';
import { ChartAxis } from './chart-axis';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import * as chroma from 'chroma-js';
import { ChartPoint } from './chart-point';
import { ChartStar } from './chart-star';
import { NumberLineDataset } from './number-line-dataset';
import { ChartAxisBuilder } from './chart-axis-builder';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mtn-number-line-chart',
  templateUrl: './number-line-chart.component.html',
  styleUrls: ['./number-line-chart.component.scss']
})
export class NumberLineChartComponent implements AfterViewInit, OnInit, OnDestroy {

  private readonly GRADIENT = chroma.scale(['red', 'orange', 'limegreen']);

  @Input()
  dataset: NumberLineDataset;
  @Input()
  axisBuilder: ChartAxisBuilder;
  @Input()
  isTooltipsEnabled = false;
  @Input()
  showAxis: boolean;

  @ViewChild('chart')
  p5Element: ElementRef;

  axis: ChartAxis;

  padding = 25;

  chartWidth: number;
  chartStartX: number;
  chartEndX: number;
  chartTop = 0;
  chartBottom: number;
  chartCenter: number;

  points: ChartPoint[] = [];

  medianPx: number;

  @ViewChild('toolTipAnchor', {read: MatTooltip})
  toolTip: MatTooltip;
  toolTipText: string = '';
  toolTipX: number = 0;
  toolTipY: number = 0;

  destroyed = false;

  ip5: p5;

  ngOnInit() {
    this.axisBuilder.notifier
      .pipe(debounceTime(500))
      .subscribe((axis: ChartAxis) => {
        if (!this.destroyed) {
          this.axis = axis;
          this.initChart();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.ip5?.remove();
  }

  ngAfterViewInit(): void {
    const bpContainer = this.p5Element.nativeElement;
    this.axisBuilder.setWidth(bpContainer.clientWidth);
  }

  private initChart() {
    if (!!this.ip5) {
      this.ip5.remove();
      delete this.ip5;
    }
    const sketch = (s: p5) => {
      s.setup = () => {
        // Create the actual canvas element
        s.createCanvas(this.axis.width, this.p5Element.nativeElement.clientHeight);

        // Calculate the chart layout values
        this.chartWidth = s.width - (this.padding * 2);
        this.chartStartX = this.padding;
        this.chartEndX = this.chartWidth + this.padding;
        this.chartTop = 18;
        this.chartBottom = this.showAxis ? s.height - 20 : s.height; // Minus enough room for axis labels
        this.chartCenter = (this.chartTop + this.chartBottom) / 2;

        // Move subject to end so it is drawn on top of everything else
        const subjectIndex = this.dataset.storeRankings.findIndex(storeRanking => storeRanking.store?.uuid == this.dataset.subject.uuid);
        const subject = this.dataset.storeRankings.splice(subjectIndex, 1)[0];
        this.dataset.storeRankings.push(subject);

        // Calculate layout values
        this.medianPx = s.map(this.dataset.median, this.axis.bottom, this.axis.top, this.chartStartX, this.chartEndX);

        // Create points
        const axisBottom = this.axis.tickValues[0];
        const axisTop = this.axis.tickValues[this.axis.tickValues.length - 1];
        this.points = this.dataset.storeRankings.map(sr => {
          const isSubject = sr.store?.uuid == this.dataset.subject.uuid;

          const x = s.map(sr.value, axisBottom, axisTop, this.chartStartX, this.chartEndX);
          if (isSubject) {
            const pos = s.createVector(x, this.chartCenter);
            return new ChartStar(s, pos, s.color('#00ffff'), sr);
          } else {
            const y = s.random(this.chartTop + 5, this.chartBottom - 5);
            const pos = s.createVector(x, y);
            const color: p5.Color = s.color(this.GRADIENT(sr.percentile / 100).css())
            return new ChartPoint(s, pos, color, sr);
          }
        })
      }

      s.draw = () => {
        s.background('#1a1a1a');

        this.drawAxis(s);

        let hoveredPoint = null;
        this.points.forEach(p => {
          p.update();
          if (this.isTooltipsEnabled && p.isHovered) {
            hoveredPoint = p;
            this.drawReferenceLine(p.x, p.color);
          }
          p.draw();
          if (p instanceof ChartStar) {
            this.drawReferenceLine(p.x, p.color);
          }
        });

        if (this.isTooltipsEnabled && !!hoveredPoint) {
          this.drawTooltip(hoveredPoint);
        } else {
          this.toolTip.hide();
        }

        const label = 'Median: ' + this.axis.generateLabel(this.dataset.median);
        this.drawReferenceLine(this.medianPx, this.ip5.color(255), label);
      };
    }
    this.ip5 = new p5(sketch, this.p5Element.nativeElement);
  }

  private drawReferenceLine(xPos: number, color: p5.Color, label?: string) {
    this.ip5.push();
    this.ip5.strokeWeight(2);
    this.ip5.stroke(color);
    this.ip5.line(xPos, this.chartTop, xPos, this.chartBottom);
    this.ip5.pop();

    if (!!label) {
      this.ip5.push();
      this.ip5.noStroke();
      this.ip5.fill(255);
      this.ip5.textAlign(this.ip5.CENTER, this.ip5.TOP);
      this.ip5.text(label, xPos, 5);
      this.ip5.pop();
    }
  }

  private drawAxis(s: p5) {
    const y = s.height;
    let tickSeparationPx = this.chartWidth / (this.axis.tickValues.length - 1);

    s.textAlign(s.CENTER, s.BOTTOM);

    for (let i = 0; i < this.axis.tickValues.length; i++) {
      const tickX = (tickSeparationPx * i) + this.chartStartX;
      const label = this.axis.tickLabels[i];

      if (this.showAxis) {
        // labels
        s.noStroke();
        s.fill(255);
        s.text(label, tickX, y);
      }

      // lines
      s.stroke(255, 255, 255, 120);
      s.strokeWeight(0.5);
      s.line(tickX, this.chartTop, tickX, this.chartBottom);
    }
  }

  private drawTooltip(p: ChartPoint) {
    let text = '';
    if (p instanceof ChartStar) {
      text += '(Subject Store}\n\n';
    }
    text += 'Name: ' + p.storeName;
    if (!!p.storeNumber) {
      text += ` (${p.storeNumber})`;
    }
    text += `\n${this.axis.valueKey}: ${this.axis.generateLabel(p.val)}`;
    this.toolTipText = text;
    this.toolTipX = p.x;
    this.toolTipY = p.y;
    this.toolTip.show();
  }

  pause() {
    this.ip5?.noLoop();
  }

  resume() {
    this.ip5?.loop();
  }

}
