import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { AddComparisonWizard } from '../add-comparison-wizard';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegionType } from '../../../../../core/location/region-type.enum';
import { StoreComparison } from '../../../../../core/store/ranking/store-comparison';

@Component({
  selector: 'mtn-add-comparison-page',
  templateUrl: './add-comparison-page.component.html',
  styleUrls: ['./add-comparison-page.component.scss']
})
export class AddComparisonPageComponent extends WizardPageComponent<AddComparisonWizard> {

  private readonly VALIDATORS_DRIVE_TIME = [Validators.min(1), Validators.max(20)];
  private readonly VALIDATORS_RING = [Validators.min(1), Validators.max(10)];

  key = 'add-comparison';

  form = new UntypedFormGroup({
    comparisonType: new UntypedFormControl(null, [Validators.required]),
    driveTimeMinutes: new UntypedFormControl(7, this.VALIDATORS_DRIVE_TIME),
    region: new UntypedFormControl(null, [Validators.required]),
    ringMiles: new UntypedFormControl(3, this.VALIDATORS_RING)
  });
  RegionType = RegionType;

  constructor() {
    super();
    this.title = 'Add Competition Comparison';
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Add';
  }

  onLoad(): Observable<any> {
    this.subscribeToFormChanges();

    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();
    formValue.store = this.wizard.model.store;
    formValue.salesSqftDisplayType = this.wizard.model.userPreferences.salesSqftDisplayMode;
    formValue.salesVolumeDisplayType = this.wizard.model.userPreferences.salesVolumeDisplayMode;

    this.wizard.model.comparison = new StoreComparison(formValue);

    return of(null);
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges
        .subscribe((value: any) => {
          //Update driveTimeMinutes validators
          const driveTimeMinutesValidators = [...this.VALIDATORS_DRIVE_TIME];
          if (value.region === RegionType.DRIVE_TIME) {
            driveTimeMinutesValidators.push(Validators.required)
          }
          this.form.get('driveTimeMinutes').setValidators(driveTimeMinutesValidators);

          //Update ringMiles validators
          const ringMilesValidators = [...this.VALIDATORS_RING];
          if (value.region === RegionType.RING) {
            ringMilesValidators.push(Validators.required);
          }
          this.form.get('ringMiles').setValidators(ringMilesValidators);

          this.form.updateValueAndValidity({emitEvent: false});
        })
    );
  }

}
