import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../wizard/wizard-page-component';
import { ConfirmRemoveAccessWizard } from '../confirm-remove-access-wizard';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { ToastService } from '../../../toast/toast.service';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MtnValidators } from '../../../form-controls/mtn-validators';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-confirm-remove-access-page',
  templateUrl: './confirm-remove-access-page.component.html',
  styleUrls: ['./confirm-remove-access-page.component.scss']
})
export class ConfirmRemoveAccessPageComponent extends WizardPageComponent<ConfirmRemoveAccessWizard> {

  key = 'confirm-remove-access';

  constructor(private userService: UserProfileService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'No';
    this.nextButtonText = 'Yes';
    this.nextButtonColor = 'warn';
    this.footerText = 'Are you sure you want to do this?';
    this.title = 'Revoke Access to MTN Insights';
    this.subheaderText = `You are about to revoke all access to MTN Insights for ${this.wizard.model.user.getDisplayName()}. Revoking access will remove all associations to any Companies and Teams and prevent the user from logging in.<br/><br/>To confirm you want to do this, type "<i>${this.wizard.model.user.getDisplayName()}</i>" below and click "Yes".`;

    this.form = new UntypedFormGroup({
      confirmation: new UntypedFormControl(null, [Validators.required, MtnValidators.equals(this.wizard.model.user.getDisplayName())])
    });

    return of(null);
  }

  onNext(): Observable<string> {
    return this.userService.deleteOne(this.wizard.model.user.uuid)
      .pipe(map(() => {
        this.toaster.info("Successfully removed user's access");
        this.wizard.model.isDeleted = true;
        return null;
      }));
  }

}
